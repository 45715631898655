<template>
  <div>
    <head-bar v-if="activePet" :title="milestoneGroup ? milestoneGroup.translation.title : ''">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../../assets/arrow-left.svg" alt="" aria-hidden="true" />
          Terug
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="group">
      <div v-if="milestoneGroup" class="p-2 p-md-3">
        <ul>
          <li v-for="milestone in milestones" :key="milestone.id">
            <row-button
              class="mb-2"
              :to="{ name: milestone.routeName, params: { slug: milestone.routeSlug } }"
              :checked="milestone.completed"
            >
              {{ milestone.translation.title }}
            </row-button>
          </li>
        </ul>
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLayout from '../AppLayout.vue';
import HeadBar from '../../components/HeadBar.vue';
import HeadBarLink from '../../components/HeadBarLink.vue';
import RowButton from '../../components/RowButton.vue';

export default {
  components: {
    RowButton,
    HeadBarLink,
    HeadBar,
    AppLayout,
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapGetters([
      'user',
      'activePet',
    ]),

    milestoneGroup() {
      if (!this.user) {
        return null;
      }

      const routeId = parseInt(this.$route.params.id, 10);

      const activeMilestones = this.user.active_milestones;

      const milestoneGroupIds = Object.keys(activeMilestones).map((s) => parseInt(s, 10));

      if (milestoneGroupIds.indexOf(routeId) < 0) {
        return null;
      }

      const milestones = activeMilestones[routeId];

      const milestoneGroup = milestones[0].milestone_groups.find((group) => group.id === routeId);

      return typeof milestoneGroup !== 'undefined' ? milestoneGroup : null;
    },

    milestones() {
      if (!this.milestoneGroup) {
        return null;
      }

      if (!this.user.active_milestones[this.milestoneGroup.id]) {
        return null;
      }

      return this.user.active_milestones[this.milestoneGroup.id].map((milestone) => {
        const milestoneClone = { ...milestone };

        milestoneClone.routeName = milestoneClone.primary_article ? 'Snack' : 'Milestone';
        milestoneClone.routeSlug = milestoneClone.primary_article
          ? milestoneClone.primary_article.translation.slug
          : milestoneClone.translation.slug;

        return milestoneClone;
      });
    },
  },
};
</script>

<style>
.app-layout.group {
  padding-top: 3.5rem;
  background-color: var(--background-color);
}
</style>
